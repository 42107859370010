import '@/styles/globals.css'
import type { AppProps } from 'next/app'
import { useEffect } from 'react'

import { SessionProvider } from 'next-auth/react'

import MainLayout from '../components/Layouts/main'
import { ChakraProvider } from '@chakra-ui/react'
import { AnimatePresence } from 'framer-motion'
import theme from '../theme/styles'
import { Comfortaa, Poppins, Lexend } from 'next/font/google'

import { Analytics } from '@vercel/analytics/react'
import { datadogRum } from '@datadog/browser-rum'

const comfortaa = Comfortaa({
  subsets: ['latin'],
  variable: '--font-comfortaa',
})
const poppins = Poppins({
  subsets: ['latin'],
  weight: ['400', '500', '600', '700'],
  variable: '--font-poppins',
})

const lexend = Lexend({
  subsets: ['latin'],
  variable: '--font-lexend',
})

export default function App({
  Component,
  pageProps: { session, ...pageProps },
}: AppProps) {
  /*
  useEffect(() => {
    datadogRum.init({
      applicationId: '3380fb69-1212-4f16-acce-c55a5c6ce5bc',
      clientToken: 'pube1e22ade4304135bbb323ce5eb852d9e',
      site: 'us5.datadoghq.com',
      service: 'studentwise',
      env: 'prod',
      // Specify a version number to identify the deployed version of your application in Datadog
      // version: '1.0.0',
      sessionSampleRate: 100,
      premiumSampleRate: 100,
      trackUserInteractions: true,
      defaultPrivacyLevel: 'mask-user-input',
    })
    datadogRum.startSessionReplayRecording()
  }, [])
*/

  return (
    <ChakraProvider theme={theme}>
      <SessionProvider session={session}>
        <MainLayout>
          <AnimatePresence initial={true}>
            <div
              className={`${comfortaa.variable} ${poppins.variable} ${lexend.variable} font-helvetica`}
            >
              <Component {...pageProps} />
            </div>
          </AnimatePresence>
          <Analytics />
        </MainLayout>
      </SessionProvider>
    </ChakraProvider>
  )
}
